const context = require.context('./modules/', false, /([a-z_]+)\.js$/i);

const toCamelCase = (string) => string.replace(/[^a-zA-Z0-9]+(.)/g,
  (m, chr) => chr.toUpperCase()).replace(/^\w/, (c) => c.toLowerCase());

const storeModules = context
  .keys().map((key) => ({ key, name: toCamelCase(key.match(/([a-z_]+)\.js$/i)[1]) }))
  .reduce((modules, { key, name }) => ({ ...modules, [name]: context(key).default }), {});

export default storeModules;
