import Vue from 'vue';
import interceptorsSetup from '@/services/clients/interceptor';
import App from './App.vue';
import router from './router';
import store from './store';

// Element
import '@/plugins/element';
// eCharts
import '@/plugins/eCharts';
// BootstrapVue
import '@/plugins/bootstrapvue';
// primeVue
import '@/plugins/primevue';
// JWT decoder
import '@/plugins/jwtDecoder';
// Sweet alert
import '@/plugins/sweetalert';
// filters
import './utils/filters';

interceptorsSetup();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
