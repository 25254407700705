import Repository from '../../services/repositories/RepositoryFactory';

const UserRepository = Repository.service('users');

export default {
  namespaced: true,
  // ----------------------------------------------------------------------------------
  state: {
    user: {
      name: '',
      password: '',
    },
  },
  // ----------------------------------------------------------------------------------
  getters: {
    getUsername(state) {
      return state.user.username;
    },
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setUsername(state, username) {
      state.user.username = username;
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {
    async login(_, payload) {
      const response = await UserRepository.login(payload);
      return response;
    },
  },
};
