import api from '../clients/axios';

const resource = '/api/v1';

export default {

  async login(payload) {
    return api.post(`${resource}/login/access-token`, payload);
  },

};
