import UserRepository from './UserRepository';
import ScaleRepository from './ScaleRepository';

const repositories = {
  users: UserRepository,
  scales: ScaleRepository,
};

export default {
  service: (name) => repositories[name],
};
