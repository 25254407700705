const ScalesRoute = [
  {
    path: '/scales',
    name: 'Scales',
    component: () => import('../../views/Scales/Index.vue'),
    props: true,
  },
];

export default ScalesRoute;
